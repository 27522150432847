import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import TitleTop from '../components/Title/TitleTop'
import styled from '@emotion/styled'
import color from '../styles/color'

const NewsList = () => {
  const { allShopifyArticle } = useStaticQuery(
    graphql`
      query {
        allShopifyArticle {
          edges {
            node {
              id
              contentHtml
              title
              handle
              publishedAt(formatString: "YYYY.M.D")
            }
          }
        }
      }
    `
  )

  const item = allShopifyArticle.edges

  return (
    <Container>
      <TitleTop label="お知らせ一覧" en="news" />
      {item &&
        item.map(({ node: { id, handle, title, publishedAt } }) => (
          <Link to={`/news/${handle}`} key={id}>
            <List>
              <Day>{publishedAt}</Day>
              <Title>{title}</Title>
            </List>
          </Link>
        ))}
    </Container>
  )
}

export default NewsList

const Container = styled.div`
  padding: 15px;
  max-width: 1000px;
  margin: 0 auto;
`

const List = styled.div`
  margin-bottom: 10px;
`

const Title = styled.div`
  font-size: 15px;
  color: ${color.BLACK};
`

const Day = styled.div`
  font-size: 12px;
  color: ${color.BLACK};
`
